import { useState, useEffect } from 'react';

export function useHasMounted() {
  const [hasMounted, setHasMounted] = useState(false);

  useEffect(() => {
    setHasMounted(true);
  }, []);

  return hasMounted;
}


// thanks Dan Abramov! https://gist.github.com/gaearon/cb5add26336003ed8c0004c4ba820eae
export const useWindowWidth = (): number => {
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  });

  return width;
};

// listen for post messages from the player for showing/hiding a hero's overlay/gradient
export const useIsUserHoveringOverVideo = () => {
  const [isUserHoveringOverVideo, setIsUserHoveringOverVideo] = useState(true);

  useEffect(() => {
      const handlePostMessage = (event: MessageEvent) => {
          let messageType;

          if (typeof event.data === 'string') {
              const messageData = event.data.match(/"event":"videojs:([^"]+)"/);
              messageType = messageData ? messageData[1] : null;
              if (messageType === 'userIsHoveringOverVideo') {
                  setIsUserHoveringOverVideo(true);
              } else if (messageType === 'userNotHoveringOverVideo') {
                  setIsUserHoveringOverVideo(false);
              }
          }
      }
      window.addEventListener('message', handlePostMessage);
      return () => {
          window.removeEventListener('message', handlePostMessage);
      }
  }, []);

  return isUserHoveringOverVideo;
}

// listen for post messages from the player that it's ready
export const useIsPlayerReady = () => {
  const [isPlayerReady, setIsPlayerReady] = useState(false);

  useEffect(() => {
      const handlePostMessage = (event: MessageEvent) => {
          let messageType;

          if (typeof event.data === 'string') {
              const messageData = event.data.match(/"event":"videojs:([^"]+)"/);
              messageType = messageData ? messageData[1] : null;
              if (messageType === 'playerReady') {
                  setIsPlayerReady(true);
              }
          }
      }
      window.addEventListener('message', handlePostMessage);
      return () => {
          window.removeEventListener('message', handlePostMessage);
      }
  }, []);

  return isPlayerReady;
}
