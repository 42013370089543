enum CompanionState {
  NotLoaded = 'NotLoaded',
  IsLoading = 'IsLoading',
  IsLoadedWithData = 'IsLoadedWithData',
  IsLoadedWithoutData = 'IsLoadedWithoutData',
  WillNotLoad = 'WillNotLoad',
  Error = 'Error'
}

export default CompanionState
