import { ORIGIN } from '@/lib/constants'
import safeFetch from '@/lib/helpers/safe-fetch'

export async function addFavoriteVideo(id: string) {
  return await post(`${ORIGIN}/data/profile/addFavoriteVideo`, id)
}

export async function removeFavoriteVideo(id: string) {
  return await post(`${ORIGIN}/data/profile/removeFavoriteVideo`, id)
}

export async function addFavoriteShow(id: string) {
  return await post(`${ORIGIN}/data/profile/addFavoriteShow`, id)
}

export async function removeFavoriteShow(id: string) {
  return await post(`${ORIGIN}/data/profile/removeFavoriteShow`, id)
}

export async function removeWatchedVideo(id: string) {
  return await post(`${ORIGIN}/data/profile/removeWatchedVideo`, id)
}

async function post(url: string, id: string) {
  const response = await safeFetch(`${url}/${id}/`, { method: 'POST', credentials: 'include'})
  return await response.json()
}
