import { ProfileData } from "@/lib/types/api/profile-data"
import { atom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';
import CompanionState from "@/lib/types/atoms/companionState";

let storedProfile

if (typeof window !== "undefined") {
  storedProfile = JSON.parse(window.localStorage.getItem('profile')!) as ProfileData
} else {
  storedProfile = null
}

export const userProfile = atomWithStorage<ProfileData | null>('profile', storedProfile);

export const userProfileStatus = atom<CompanionState>(CompanionState.NotLoaded)
