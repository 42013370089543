import { ReactNode } from "react";
import Link from 'next/link';
import AddIcon from '/public/svg/add.svg';
import PlayIcon from '/public/svg/play.svg';
import CheckIcon from '/public/svg/check.svg';
import DownIcon from '/public/svg/down.svg';
import ShuffleIcon from '/public/svg/shuffle.svg';
import styles from './Button.module.scss';
export interface LinkButtonProps {
  children: ReactNode;
  className?: string;
  gtmLabel?: string;
  href: string | {
    pathname: string;
    query: {
      [key: string]: string;
    };
  };
  iconBefore?: "play" | "add" | "check";
  iconAfter?: "down" | "shuffle";
  style?: "white_ghost" | "blue_ghost" | "white" | "blue" | "light_blue" | "yellow" | "red";
  target?: "_blank";
  title?: string;
  size?: 'responsive' | 'min' | 'max';
}
const LinkButton = (props: LinkButtonProps) => {
  const {
    children,
    className,
    gtmLabel,
    href,
    style = "white_ghost",
    target,
    title,
    iconBefore,
    iconAfter,
    size = 'responsive'
  } = props;
  let IconBefore = null;
  switch (iconBefore) {
    case 'play':
      IconBefore = <PlayIcon />;
      break;
    case 'add':
      IconBefore = <AddIcon />;
      break;
    case 'check':
      IconBefore = <CheckIcon />;
      break;
  }
  let IconAfter = null;
  switch (iconAfter) {
    case 'down':
      IconAfter = <DownIcon />;
      break;
    case 'shuffle':
      IconAfter = <ShuffleIcon />;
      break;
  }
  let classNames = `${styles[style]}`;
  if (className) {
    classNames += ` ${className}`;
  }
  if (size !== 'responsive') {
    classNames += ` ${styles[size]}`;
  }
  return <Link href={href} className={classNames} target={target} title={title} data-gtm-label={gtmLabel} rel={target === "_blank" ? "noopener noreferrer" : undefined} data-sentry-element="Link" data-sentry-component="LinkButton" data-sentry-source-file="LinkButton.tsx">
      {IconBefore}
      {children}
      {IconAfter}
    </Link>;
};
export default LinkButton;